import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import Modal from "../modal";

type PropsType = {
  shareLink: string;
  onClose: () => void;
};

const Index = ({ shareLink, onClose }: PropsType) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={!!shareLink}
      onClose={onClose}
      title={t("Share Link")}
    >
      <div className="copy-container">
        <div className="copy-text">{shareLink}</div>
        <CopyToClipboard text={shareLink}>
          <motion.button
            className="copy-btn"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            {t("Copy")}
          </motion.button>
        </CopyToClipboard>
      </div>
    </Modal>
  );
};

export default Index;
