import alaskaLogo from "./assets/airline-logos/alaska.webp";
import anaLogo from "./assets/airline-logos/ana.webp";
import britishLogo from "./assets/airline-logos/british.webp";
import canadaLogo from "./assets/airline-logos/canada.webp";
import crLogo from "./assets/airline-logos/cr.webp";
import deltaLogo from "./assets/airline-logos/delta.webp";
import dfLogo from "./assets/airline-logos/df.webp";
import emiratesLogo from "./assets/airline-logos/emirates.webp";
import franceLogo from "./assets/airline-logos/france.webp";
import gtLogo from "./assets/airline-logos/gt.webp";
import hawaiianLogo from "./assets/airline-logos/hawaiian.webp";
import hnLogo from "./assets/airline-logos/hn.webp";
import jalLogo from "./assets/airline-logos/jal.webp";
import jxLogo from "./assets/airline-logos/jx.webp";
import klmLogo from "./assets/airline-logos/klm.webp";
import koreanLogo from "./assets/airline-logos/korean.webp";
import lufthansaLogo from "./assets/airline-logos/lufthansa.webp";
import qantasLogo from "./assets/airline-logos/qantas.webp";
import qatarLogo from "./assets/airline-logos/qatar.webp";
import ryanLogo from "./assets/airline-logos/ryan.webp";
import scLogo from "./assets/airline-logos/sc.webp";
import scootLogo from "./assets/airline-logos/scoot.webp";
import sdLogo from "./assets/airline-logos/sd.webp";
import singaporeLogo from "./assets/airline-logos/singapore.webp";
import southwestLogo from "./assets/airline-logos/south-west.webp";
import spiritLogo from "./assets/airline-logos/spirit.webp";
import springLogo from "./assets/airline-logos/spring.webp";
import starluxLogo from "./assets/airline-logos/starlux.webp";
import szLogo from "./assets/airline-logos/sz.webp";
import thaiLogo from "./assets/airline-logos/thai.webp";
import unitedLogo from "./assets/airline-logos/united.webp";
import xmLogo from "./assets/airline-logos/xm.webp";
import zggjLogo from "./assets/airline-logos/zggj.webp";
import zgnfLogo from "./assets/airline-logos/zgnf.webp";
import zhhkLogo from "./assets/airline-logos/zhhk.webp";


export const AIRLINES = [
  {
    "id": "1",
    "name": "阿拉斯加航空",
    "nameEn": "Alaska Airlines",
    "logo": alaskaLogo
  },
  {
    "id": "2",
    "name": "全日空航空",
    "nameEn": "ANA All Nippon Airways",
    "logo": anaLogo
  },
  {
    "id": "3",
    "name": "英国航空",
    "nameEn": "British Airways",
    "logo": britishLogo
  },
  {
    "id": "4",
    "name": "加拿大航空",
    "nameEn": "Air Canada",
    "logo": canadaLogo
  },
  {
    "id": "5",
    "name": "长荣航空",
    "nameEn": "EVA Air",
    "logo": crLogo
  },
  {
    "id": "6",
    "name": "达美航空",
    "nameEn": "Delta Air Lines",
    "logo": deltaLogo
  },
  {
    "id": "7",
    "name": "中国东方航空",
    "nameEn": "China Eastern",
    "logo": dfLogo
  },
  {
    "id": "8",
    "name": "阿联酋航空",
    "nameEn": "Emirates",
    "logo": emiratesLogo
  },
  {
    "id": "9",
    "name": "法国航空",
    "nameEn": "Air France",
    "logo": franceLogo
  },
  {
    "id": "10",
    "name": "国泰航空",
    "nameEn": "Cathay Pacific",
    "logo": gtLogo
  },
  {
    "id": "11",
    "name": "夏威夷航空",
    "nameEn": "Hawaiian Airlines",
    "logo": hawaiianLogo
  },
  {
    "id": "12",
    "name": "海南航空",
    "nameEn": "Hainan Airlines",
    "logo": hnLogo
  },
  {
    "id": "13",
    "name": "日本航空",
    "nameEn": "Japan Airlines (JAL)",
    "logo": jalLogo
  },
  {
    "id": "14",
    "name": "吉祥航空",
    "nameEn": "Juneyao Airlines",
    "logo": jxLogo
  },
  {
    "id": "15",
    "name": "荷兰皇家航空",
    "nameEn": "KLM",
    "logo": klmLogo
  },
  {
    "id": "16",
    "name": "大韩航空",
    "nameEn": "Korean Air",
    "logo": koreanLogo
  },
  {
    "id": "17",
    "name": "汉莎航空",
    "nameEn": "Lufthansa",
    "logo": lufthansaLogo
  },
  {
    "id": "18",
    "name": "澳洲航空",
    "nameEn": "Qantas",
    "logo": qantasLogo
  },
  {
    "id": "19",
    "name": "卡塔尔航空",
    "nameEn": "Qatar Airways",
    "logo": qatarLogo
  },
  {
    "id": "20",
    "name": "瑞安航空",
    "nameEn": "Ryanair",
    "logo": ryanLogo
  },
  {
    "id": "21",
    "name": "四川航空",
    "nameEn": "Sichuan Airlines",
    "logo": scLogo
  },
  {
    "id": "22",
    "name": "酷航",
    "nameEn": "Scoot",
    "logo": scootLogo
  },
  {
    "id": "23",
    "name": "山东航空",
    "nameEn": "Shandong Airlines",
    "logo": sdLogo
  },
  {
    "id": "24",
    "name": "新加坡航空",
    "nameEn": "Singapore Airlines",
    "logo": singaporeLogo
  },
  {
    "id": "25",
    "name": "西南航空",
    "nameEn": "Southwest Airlines",
    "logo": southwestLogo
  },
  {
    "id": "26",
    "name": "精神航空",
    "nameEn": "Spirit Airlines",
    "logo": spiritLogo
  },
  {
    "id": "27",
    "name": "春秋航空",
    "nameEn": "Spring Airlines",
    "logo": springLogo
  },
  {
    "id": "28",
    "name": "星宇航空",
    "nameEn": "STARLUX",
    "logo": starluxLogo
  },
  {
    "id": "29",
    "name": "深圳航空",
    "nameEn": "Shenzhen Airlines",
    "logo": szLogo
  },
  {
    "id": "30",
    "name": "泰国国际航空",
    "nameEn": "Thai Airways",
    "logo": thaiLogo
  },
  {
    "id": "31",
    "name": "美国联合航空",
    "nameEn": "United Airlines",
    "logo": unitedLogo
  },
  {
    "id": "32",
    "name": "厦门航空",
    "nameEn": "Xiamen Airlines",
    "logo": xmLogo
  },
  {
    "id": "33",
    "name": "中国国际航空",
    "nameEn": "Air China",
    "logo": zggjLogo
  },
  {
    "id": "34",
    "name": "中国南方航空",
    "nameEn": "China Southern",
    "logo": zgnfLogo
  },
  {
    "id": "35",
    "name": "中华航空",
    "nameEn": "China Airlines",
    "logo": zhhkLogo
  }
];

export const AIRLINES_ORDER = [
  "2",
  "28",
  "8",
  "19",
  "35",
  "16",
  "24",
  "15",
  "13",
  "9",
  "30",
  "10",
  "5",
  "33",
  "12",
  "32",
  "17",
  "29",
  "21",
  "18",
  "34",
  "14",
  "6",
  "1",
  "3",
  "23",
  "11",
  "7",
  "31",
  "4",
  "22",
  "26",
  "20",
  "27",
  "25"
]
