import React from "react";
import { motion } from "framer-motion";
import "./index.scss";

type PropsType = {
  onClick?: () => void;
  children?: React.ReactNode;
};

const Index = ({onClick = () => {}, children}: PropsType) => {
  return (
    <motion.button
      className="fab-btn"
      onClick={onClick}
      whileHover={{ scale: 1.2 }}
      whileTap={{ scale: 0.8 }}
    >
      {children}
    </motion.button>
  );
};

export default Index;
