import { useState, useMemo, useEffect } from "react";
import queryString from "query-string";
import { ShareAltOutlined } from "@ant-design/icons";
import OrderableList from "./components/orderable-list";
import LanguageSelector from "./components/language-selector";
import FabBtn from "./components/fab-btn";
import ShareModal from "./components/share-modal";


import { AIRLINES, AIRLINES_ORDER } from "./constants";
import "./App.scss";

const App = () => {
  
  const [shareLink, setShareLink] = useState("");
  const [sortIdList, setSortIdList] = useState(AIRLINES_ORDER);
  const sortedList = useMemo(() => {
    const airlines = [...AIRLINES];
    const result: Airline[] = [];
    sortIdList.forEach(id => {
      const index = airlines.findIndex(item => item.id === id);
      if (index !== -1) {
        result.push(airlines[index]);
        airlines.splice(index, 1);
      }
    });
    return [...result, ...airlines];
  }, [sortIdList]);
  useEffect(() => {
    const query = queryString.parse(window.location.search);
    if (query.sort) {
      const decodedString = atob(query.sort as string);
      const decompressedString = decodedString
        .split(",")
        .map(num => parseInt(num, 36).toString());
      setSortIdList(decompressedString);
    }
  }, []);
  const handleSetList = (list: Airline[]) => {
    setSortIdList(list.map(item => item.id));
  };
  const handleShare = () => {
    const compressedString = sortIdList
      .map(num => parseInt(num).toString(36))
      .join(",");
    const encodedString = btoa(compressedString);
    const shareUrl = queryString.stringifyUrl({
      url: window.location.href,
      query: {
        sort: encodedString
      }
    });
    setShareLink(shareUrl);
  };

  return (
    <div className="app">
      <OrderableList list={sortedList} setList={handleSetList} />
      <div className="fixed-btns">
        <FabBtn onClick={handleShare}>
          <ShareAltOutlined />
        </FabBtn>
        <LanguageSelector />
      </div>
      <ShareModal shareLink={shareLink} onClose={() => setShareLink("")} />
    </div>
  );
};

export default App;
