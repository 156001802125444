import { useTranslation } from "react-i18next";
import Fab from "../fab-btn";

const lngs = {
  en: "en",
  zh: "中"
};

const Index = () => {
  const { t, i18n } = useTranslation();
  /** 获取当前语言之外的另一个语言 */
  const anotherLng = Object.keys(lngs).find(lng => lng !== i18n.language) as
    | "en"
    | "zh";
  const handleLanguageChange = () => {
    i18n.changeLanguage(anotherLng);
  };
  return (
    <Fab onClick={handleLanguageChange}>
      {lngs[anotherLng]}
    </Fab>
  );
};

export default Index;
