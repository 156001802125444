import * as React from "react";
import { useMotionValue, Reorder } from "framer-motion";
import { useTranslation } from 'react-i18next';
import { useRaisedShadow } from "./use-raised-shadow";

interface Props {
  item: Airline;
}

const MOCK_TICKET = {
  name: "Zhu Wang",
  date: "22 JUN 2024",
  dest: "EARTH",
  gate: "A27",
  seat: "08A"
};

export const Item = ({ item }: Props) => {
  const y = useMotionValue(0);
  const boxShadow = useRaisedShadow(y);
  const { t, i18n } = useTranslation();
  const isEn = i18n.language === "en";

  return (
    <Reorder.Item value={item} id={item.id} style={{ boxShadow, y }}>
      <div className="logo-container">
        {item.logo && <img src={item.logo} alt={item.name} />}
      </div>
      <div className="mock-ticket">
        <div className="name-section">
          <div className="">{t('NAME OF AIRLINE')}</div>
          <div className="bold">{isEn ? item.nameEn : item.name}</div>
        </div>
        <div className="flight-section">
          <div>
            <div className="secondary">{t("DATE")}</div>
            <div className="">{MOCK_TICKET.date}</div>
          </div>
          <div>
            <div className="secondary">{t("NAME")}</div>
            <div className="">{MOCK_TICKET.name}</div>
          </div>
        </div>
        <div className="boarding-section">
          <div>
            <div className="secondary">{t("DEST")}</div>
            <div className="">{MOCK_TICKET.dest}</div>
          </div>
          <div>
            <div className="secondary">{t("GATE")}</div>
            <div className="">{MOCK_TICKET.gate}</div>
          </div>
          <div>
            <div className="secondary">{t("SEAT")}</div>
            <div className="">{MOCK_TICKET.seat}</div>
          </div>
        </div>
      </div>
    </Reorder.Item>
  );
};
