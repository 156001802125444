import { motion, AnimatePresence } from "framer-motion";
import { CloseOutlined } from "@ant-design/icons";
import "./index.scss";

type PropsType = {
  title: string;
  children: any;
  onClose: () => void;
  isOpen: boolean;
};

const Index = ({ title, children, onClose, isOpen }: PropsType) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="modal-mask"
          onClick={onClose}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="modal-container"
            onClick={e => e.stopPropagation()}
            initial={{ scale: 0.9, x: "-50%", y: "0%" }}
            animate={{ scale: 1, x: "-50%", y: "-50%" }}
            exit={{ scale: 0.9, x: "-50%", y: "0%" }}
          >
            <div className="head">
              <div>{title}</div>
              <motion.button
                className="close"
                onClick={onClose}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <CloseOutlined />
              </motion.button>
            </div>
            <div>{children}</div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Index;
