import "./index.scss";
import * as React from "react";
import { useState } from "react";
import { Reorder } from "framer-motion";
import { Item } from "./item";

export default function List({ list, setList }) {

  return (
    <Reorder.Group
      className="orderable-list"
      axis="y"
      onReorder={setList}
      values={list}
      layoutScroll
    >
      {list.map((item: Airline) => (
        <Item key={item.id} item={item} />
      ))}
    </Reorder.Group>
  );
}
